export const education = [
  {
    eduDate: "2002 - 2003",
    eduTitle: "Dhaka University",
    eduIns: "Post Graduation",
    eduDesc:
      "I completed my post-graduation at Dhaka University during the 2002-2003 academic year, building a strong foundation for my professional career and academic pursuits through rigorous study and commitment to learning.",
  },
  {
    eduDate: "1998 - 2002",
    eduTitle: "Dhaka University",
    eduIns: "Graduation",
    eduDesc:
      "I completed my graduation at Dhaka University from 1998 to 2002, gaining valuable knowledge and experiences that laid the groundwork for my future endeavors in both professional and personal growth.",
  },
];
