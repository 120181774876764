import Fancybox from "../../fancybox";
import ImageGallery from "../HomeCarousel/ImageGallery";
import { Link } from "react-router-dom";
import { SectionTitle } from "../SectionTitles";

const Portfolio2 = () => {
  return (
    <section className="portWrap py-[5rem] sm:py-[6rem] md:py-[7.5rem] relative w-full">
      <div className="container sm:container md:container lg:container xl:container 2xl:max-w-[85.625rem] mx-auto">
        <SectionTitle
          title="My Photo"
          titleInner="Gallery"
          desc=""
        ></SectionTitle>
        <Fancybox>
          <div className="portList flex flex-wrap relative mx-[-.9375rem]">
            <ImageGallery />
          </div>
        </Fancybox>
        <div className="viewAll text-center w-full mt-[3.125rem] md:mt-[4.0625rem]">
          <p className="text-desc2 font-NunitoSans text-[1rem] md:text-[1.125rem]">
            Want to see my professional photos.{" "}
            <Link
              className="text-accent font-Poppins font-medium hover:text-accent2 underline underline-offset-8"
              to="/photo"
              title="Click here to View More"
            >
              Click here to View More
            </Link>
          </p>
          {/* View All */}
        </div>
      </div>
      {/* Portfolio Wrap */}
    </section>
  );
};

export default Portfolio2;
