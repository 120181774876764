import "./styles.css";

import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

export default function HomeYoutube2() {
  return (
    <div className="App grid grid-rows-1 sm:grid-rows-1 md:grid-rows-1 lg:grid-rows-1 gap-4">
      <YoutubeEmbed embedId="zu3PHQfbJpo" />
      <YoutubeEmbed embedId="VHsJjx79oa0" />
      {/* <YoutubeEmbed embedId="rmY7FaBl-_k?si=QzT4imhCJGLhBr3V&amp;start=2103" /> */}
    </div>
  );
}
