import PostImg1 from "../assets/images/blog/B3.png";
import PostImg10 from "../assets/images/blog/B12.png";
import PostImg11 from "../assets/images/blog/B13.png";
import PostImg12 from "../assets/images/blog/B14.png";
import PostImg13 from "../assets/images/blog/B15.png";
import PostImg15 from "../assets/images/blog/B17.png";
import PostImg16 from "../assets/images/blog/B18.png";
import PostImg2 from "../assets/images/blog/B4.png";
import PostImg3 from "../assets/images/blog/B5.png";
import PostImg4 from "../assets/images/blog/B6.png";
import PostImg5 from "../assets/images/blog/B7.png";
import PostImg6 from "../assets/images/blog/B8.png";
import PostImg7 from "../assets/images/blog/B9.png";
import PostImg8 from "../assets/images/blog/B10.png";
import PostImg9 from "../assets/images/blog/B11.png";

export const posts = [
  {
    id: 1,
    postImg: PostImg1,
    postDate: "May 06, 2017",
    postDateLink: "#",
    postTitle: "Misha Sawdagar and Zayed Khan in the driving seat",
    postLink:
      "https://www.dhakatribune.com/showtime/19953/misha-sawdagar-and-zayed-khan-in-the-driving-seat",
    postAuthor: "Dhakat Ribune",
    postAuthorLink: "#",
  },
  {
    id: 2,
    postImg: PostImg2,
    postDate: "February 18, 2023",
    postDateLink: "#",
    postTitle:
      "I used to have a six pack, two packs were covered by not doing gym : Zayed Khan",
    postLink:
      "https://www.ntvbd.com/entertainment/%E0%A6%86%E0%A6%AE%E0%A6%BE%E0%A6%B0-%E0%A6%86%E0%A6%97%E0%A7%87-%E0%A6%B8%E0%A6%BF%E0%A6%95%E0%A7%8D%E0%A6%B8-%E0%A6%AA%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%95-%E0%A6%9B%E0%A6%BF%E0%A6%B2-%E0%A6%9C%E0%A6%BF%E0%A6%AE-%E0%A6%A8%E0%A6%BE-%E0%A6%95%E0%A6%B0%E0%A6%BE%E0%A7%9F-%E0%A6%A6%E0%A7%81%E0%A6%87-%E0%A6%AA%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%95-1188005",
    postAuthor: "NtvBd",
    postAuthorLink: "#",
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "July 30, 2022",
    postDateLink: "#",
    postTitle: "Zayed Khan came to earth on this day",
    postLink: "https://www.rtvonline.com/entertainment/186585",
    postAuthor: "Rtvonline",
    postAuthorLink: "#",
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "May 21, 2015",
    postDateLink: "#",
    postTitle: "Zayed Khan's dream come true",
    postLink:
      "https://www.prothomalo.com/entertainment/dhallywood/%E0%A6%9C%E0%A6%BE%E0%A7%9F%E0%A7%87%E0%A6%A6-%E0%A6%96%E0%A6%BE%E0%A6%A8%E0%A7%87%E0%A6%B0-%E0%A6%B8%E0%A7%8D%E0%A6%AC%E0%A6%AA%E0%A7%8D%E0%A6%A8%E0%A6%AA%E0%A7%82%E0%A6%B0%E0%A6%A3",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "February 25, 2017",
    postDateLink: "#",
    postTitle: "At a Glance Zayed Khan",
    postLink: "https://www.thedailystar.net/showbiz/glance/zayed-khan-1366594",
    postAuthor: "The Daily Star",
    postAuthorLink: "#",
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "September 12, 2023",
    postDateLink: "#",
    postTitle: "Zayed Khan's transforms into Tikka Khan in 'Mujib' biopic",
    postLink:
      "https://www.thedailystar.net/entertainment/tv-film/news/zayed-khans-transforms-tikka-khan-mujib-biopic-3416991",
    postAuthor: "The Daily Star",
    postAuthorLink: "#",
  },
  {
    id: 7,
    postImg: PostImg7,
    postDate: "June 19, 2024",
    postDateLink: "#",
    postTitle: "Dipjol gifts generous Eid salami to Zayed Khan",
    postLink:
      "https://www.thedailystar.net/entertainment/tv-film/news/dipjol-gifts-generous-eid-salami-zayed-khan-3636771",
    postAuthor: "The Daily Star",
    postAuthorLink: "#",
  },
  {
    id: 8,
    postImg: PostImg8,
    postDate: "May 23, 2024",
    postDateLink: "#",
    postTitle:
      "Zayed Khan to attend ‘Bangladesh Festival London’ alongside rock guru James",
    postLink:
      "https://www.thedailystar.net/entertainment/music/news/zayed-khan-attend-bangladesh-festival-london-alongside-rock-guru-james-3616746",
    postAuthor: "The Daily Star",
    postAuthorLink: "#",
  },
  {
    id: 9,
    postImg: PostImg9,
    postDate: "June 29, 2024",
    postDateLink: "#",
    postTitle: "Zayed Khan on his jet-setting travels abroad",
    postLink:
      "https://www.thedailystar.net/entertainment/tv-film/news/zayed-khan-his-jet-setting-travels-abroad-3644771",
    postAuthor: "The Daily Star",
    postAuthorLink: "#",
  },
  {
    id: 10,
    postImg: PostImg10,
    postDate: "June 29, 2024",
    postDateLink: "#",
    postTitle: "A happy life of Zayed Khan",
    postLink: "https://www.prothomalo.com/entertainment/dhallywood/sh7k7at1ab",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
  {
    id: 15,
    postImg: PostImg11,
    postDate: "June 25, 2024",
    postDateLink: "#",
    postTitle: "They are three brothers in Dubai",
    postLink: "https://www.prothomalo.com/entertainment/dhallywood/b602ycvf8o",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
  {
    id: 11,
    postImg: PostImg12,
    postDate: "May 15, 2024",
    postDateLink: "#",
    postTitle: "Zayed is going back to London from Sydney",
    postLink: "https://www.prothomalo.com/entertainment/dhallywood/cf1l1omf48",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
  {
    id: 12,
    postImg: PostImg13,
    postDate: "April 14, 2024",
    postDateLink: "#",
    postTitle: "Mumtaz, Zayed Khan on Times Square billboard",
    postLink: "https://www.prothomalo.com/entertainment/song/i4his0j6bk",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
  {
    id: 13,
    postImg: PostImg15,
    postDate: "April 06, 2024",
    postDateLink: "#",
    postTitle:
      "Zayed Khan did not fulfill his mother's dream despite getting the first division",
    postLink: "https://www.prothomalo.com/video/entertainment/t5b3fuak67",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
  {
    id: 14,
    postImg: PostImg16,
    postDate: "April 02, 2024",
    postDateLink: "#",
    postTitle: "Zayed Khan's 'Sonar Char'",
    postLink: "https://www.prothomalo.com/video/cafelive/hor2qysx72",
    postAuthor: "Prothomalo",
    postAuthorLink: "#",
  },
];
