import "swiper/css";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";

import { BsPlus } from "react-icons/bs";
import HomeYoutube from "../Youtube/HomeYoutube";
import { Pagination } from "swiper";
import PatternImg2 from "../../assets/images/patternImg2.jpg";
import SectionBg from "../../assets/images/secBg8.png";
import { SectionTitleLightFull } from "../SectionTitles";
import { workExp } from "../../data/workExp";

const MyWorkExperience2 = () => {
  return (
    <section className="workExpWrap2 px-0 py-[4.5rem] sm:p-[5.5rem] md:py-[6.5rem] lg:p-[7.5rem] lg:-mt-[6.25rem] flex justify-center items-center relative w-full z-[1]">
      <div
        className="fixedBg bg-center bg-cover bg-no-repeat before:absolute before:inset-0 before:opacity-95 before:bg-dark bg-dark gradBg3 bg-blend-screen lg:rounded-r-[3.125rem] md:rounded-r-8 sm:rounded-r-5 rounded-r-0 overflow-hidden z-[1]"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:max-w-[35.9375rem] mx-auto">
        <div className="swiperWrap2 relative w-full sm:-mt-10">
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            direction={"vertical"}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            breakpoints={{
              315: {
                slidesPerView: 1,
                direction: "horizontal",
                centeredSlides: false,
              },
              766: {
                slidesPerView: 3,
                direction: "vertical",
                centeredSlides: true,
              },
              1210: {
                slidesPerView: 3,
                direction: "vertical",
                centeredSlides: true,
              },
              1280: {
                slidesPerView: 3,
                direction: "vertical",
                centeredSlides: true,
              },
            }}
            className="mySwiper"
          >
            <div className="container sm:container md:container lg:container xl:max-w-[44.6875rem] mx-auto">
              <HomeYoutube />
            </div>
          </Swiper>
        </div>
      </div>
      {/* Work Experience Wrap */}
    </section>
  );
};

export default MyWorkExperience2;
