import React from "react";
import ScrollCarousel from "scroll-carousel-react";
import poster1 from '../../assets/images/posters/Postar1.JPG'
import poster10 from '../../assets/images/posters/Postar10.JPG'
import poster11 from '../../assets/images/posters/Postar11.JPG'
import poster12 from '../../assets/images/posters/Postar12.JPG'
import poster13 from '../../assets/images/posters/Postar13.JPG'
import poster14 from '../../assets/images/posters/Postar14.JPG'
import poster15 from '../../assets/images/posters/Postar15.JPG'
import poster16 from '../../assets/images/posters/Postar16.JPG'
import poster17 from '../../assets/images/posters/Postar17.JPG'
import poster18 from '../../assets/images/posters/Postar18.JPG'
import poster19 from '../../assets/images/posters/Postar19.JPG'
import poster2 from '../../assets/images/posters/Postar2.JPG'
import poster20 from '../../assets/images/posters/Postar20.JPG'
import poster21 from "../../assets/images/posters/Postar21.JPG";
import poster22 from "../../assets/images/posters/Postar22.JPG";
import poster23 from "../../assets/images/posters/Postar23.JPG";
import poster24 from "../../assets/images/posters/Postar24.JPG";
import poster25 from "../../assets/images/posters/Postar25.JPG";
import poster26 from "../../assets/images/posters/Postar26.JPG";
import poster27 from "../../assets/images/posters/Postar27.JPG";
import poster28 from "../../assets/images/posters/Postar28.JPG";
import poster29 from "../../assets/images/posters/Postar29.JPG";
import poster3 from '../../assets/images/posters/Postar3.JPG'
import poster30 from '../../assets/images/posters/Postar30.JPG'
import poster31 from '../../assets/images/posters/Postar31.JPG'
import poster4 from '../../assets/images/posters/Postar4.JPG'
import poster5 from '../../assets/images/posters/Postar5.JPG'
import poster6 from '../../assets/images/posters/Postar6.JPG'
import poster7 from '../../assets/images/posters/Postar7.JPG'
import poster8 from '../../assets/images/posters/Postar8.JPG'
import poster9 from '../../assets/images/posters/Postar9.JPG'

const MyScrollCarousel = () => {
  return (
    <>
      <div className="container sm:container md:container lg:container xl:container 2xl:max-w-[85.625rem] mx-auto aboutUsWrap pb-[4.5rem] md:pb-[5.5rem] lg:pb-[6.5rem] xl:pb-[7.5rem] ">
        <ScrollCarousel
          autoplay
          autoplaySpeed={8}
          speed={7}
          onReady={() => console.log("I am ready")}
        >
          {[
            poster1,
            poster2,
            poster3,
            poster4,
            poster5,
            poster6,
            poster7,
            poster8,
            poster9,
            poster10,
            poster11,
            poster12,
            poster13,
            poster14,
            poster15,
            poster16,
            poster17,
            poster18,
            poster19,
            poster20,
            poster21,
            poster22,
            poster23,
            poster24,
            poster25,
            poster26,
            poster27,
            poster28,
            poster29,
            poster30,
            poster31,
          ].map((item) => (
            <div key={item} className="rounded md:mx-4">
              <img className="h-96" src={item} />
            </div>
          ))}
        </ScrollCarousel>
      </div>
    </>
  );
};

export default MyScrollCarousel;
