import Gallery1 from "../../assets/images/gallery/photoGallery/Galleryh1.jpg";
import Gallery10 from "../../assets/images/gallery/photoGallery/Galleryh10.jpg";
import Gallery11 from "../../assets/images/gallery/photoGallery/Galleryh11.jpg";
import Gallery12 from "../../assets/images/gallery/photoGallery/Galleryh12.jpg";
import Gallery13 from "../../assets/images/gallery/photoGallery/Galleryh13.jpg";
import Gallery14 from "../../assets/images/gallery/photoGallery/Galleryh14.jpg";
import Gallery15 from "../../assets/images/gallery/photoGallery/Galleryh15.jpg";
import Gallery16 from "../../assets/images/gallery/photoGallery/Galleryh16.jpg";
import Gallery17 from "../../assets/images/gallery/photoGallery/Galleryh17.jpg";
import Gallery18 from "../../assets/images/gallery/photoGallery/Galleryh18.jpg";
import Gallery19 from "../../assets/images/gallery/photoGallery/Galleryh19.jpg";
import Gallery2 from "../../assets/images/gallery/photoGallery/Galleryh2.jpg";
import Gallery20 from "../../assets/images/gallery/photoGallery/Galleryh20.jpg";
import Gallery21 from "../../assets/images/gallery/photoGallery/Galleryh21.jpg";
import Gallery22 from "../../assets/images/gallery/photoGallery/Galleryh22.jpg";
import Gallery23 from "../../assets/images/gallery/photoGallery/Galleryh23.jpg";
import Gallery24 from "../../assets/images/gallery/photoGallery/Galleryh24.jpg";
import Gallery25 from "../../assets/images/gallery/photoGallery/Galleryh25.jpg";
import Gallery26 from "../../assets/images/gallery/photoGallery/Galleryh26.jpg";
import Gallery27 from "../../assets/images/gallery/photoGallery/Galleryh27.jpg";
import Gallery28 from "../../assets/images/gallery/photoGallery/Galleryh28.jpg";
import Gallery29 from "../../assets/images/gallery/photoGallery/Galleryh29.jpg";
import Gallery3 from "../../assets/images/gallery/photoGallery/Galleryh3.jpg";
import Gallery30 from "../../assets/images/gallery/photoGallery/Galleryh30.jpg";
import Gallery31 from "../../assets/images/gallery/photoGallery/Galleryh31.jpg";
import Gallery32 from "../../assets/images/gallery/photoGallery/Galleryh32.jpg";
import Gallery33 from "../../assets/images/gallery/photoGallery/Galleryh33.jpg";
import Gallery34 from "../../assets/images/gallery/photoGallery/Galleryh34.jpg";
import Gallery35 from "../../assets/images/gallery/photoGallery/Galleryh35.jpg";
import Gallery36 from "../../assets/images/gallery/photoGallery/Galleryh36.jpg";
import Gallery37 from "../../assets/images/gallery/photoGallery/Galleryh37.jpg";
import Gallery38 from "../../assets/images/gallery/photoGallery/Galleryh38.jpg";
import Gallery39 from "../../assets/images/gallery/photoGallery/Galleryh39.jpg";
import Gallery4 from "../../assets/images/gallery/photoGallery/Galleryh4.jpg";
import Gallery40 from "../../assets/images/gallery/photoGallery/Galleryh40.jpg";
import Gallery41 from "../../assets/images/gallery/photoGallery/Galleryh41.jpg";
import Gallery42 from "../../assets/images/gallery/photoGallery/Galleryh42.jpg";
import Gallery43 from "../../assets/images/gallery/photoGallery/Galleryh43.jpg";
import Gallery44 from "../../assets/images/gallery/photoGallery/Galleryh44.jpg";
import Gallery45 from "../../assets/images/gallery/photoGallery/Galleryh45.jpg";
import Gallery46 from "../../assets/images/gallery/photoGallery/Galleryh46.jpg";
import Gallery47 from "../../assets/images/gallery/photoGallery/Galleryh47.jpg";
import Gallery48 from "../../assets/images/gallery/photoGallery/Galleryh48.jpg";
import Gallery49 from "../../assets/images/gallery/photoGallery/Galleryh49.jpg";
import Gallery5 from "../../assets/images/gallery/photoGallery/Galleryh5.jpg";
import Gallery50 from "../../assets/images/gallery/photoGallery/Galleryh50.jpg";
import Gallery51 from "../../assets/images/gallery/photoGallery/Galleryh51.jpg";
import Gallery52 from "../../assets/images/gallery/photoGallery/Galleryh52.jpg";
import Gallery6 from "../../assets/images/gallery/photoGallery/Galleryh6.jpg";
import Gallery7 from "../../assets/images/gallery/photoGallery/Galleryh7.jpg";
import Gallery8 from "../../assets/images/gallery/photoGallery/Galleryh8.jpg";
import Gallery9 from "../../assets/images/gallery/photoGallery/Galleryh9.jpg";
import { Gellary } from "../HomeCarousel/Gellary";
import { Gellary2 } from "../HomeCarousel/Gellary2";
import Single1 from "../../assets/images/gallery/singlePhoto/Gallery1.jpg";
import Single10 from "../../assets/images/gallery/singlePhoto/Gallery10.jpg";
import Single11 from "../../assets/images/gallery/singlePhoto/Gallery11.jpg";
import Single12 from "../../assets/images/gallery/singlePhoto/Gallery12.jpg";
import Single13 from "../../assets/images/gallery/singlePhoto/Gallery13.jpg";
import Single14 from "../../assets/images/gallery/singlePhoto/Gallery14.jpg";
import Single15 from "../../assets/images/gallery/singlePhoto/Gallery15.jpg";
import Single16 from "../../assets/images/gallery/singlePhoto/Gallery16.jpg";
import Single17 from "../../assets/images/gallery/singlePhoto/Gallery17.jpg";
import Single18 from "../../assets/images/gallery/singlePhoto/Gallery18.jpg";
import Single19 from "../../assets/images/gallery/singlePhoto/Gallery19.jpg";
import Single2 from "../../assets/images/gallery/singlePhoto/Gallery2.jpg";
import Single20 from "../../assets/images/gallery/singlePhoto/Gallery20.jpg";
import Single21 from "../../assets/images/gallery/singlePhoto/Gallery21.jpg";
import Single22 from "../../assets/images/gallery/singlePhoto/Gallery22.jpg";
import Single23 from "../../assets/images/gallery/singlePhoto/Gallery23.jpg";
import Single24 from "../../assets/images/gallery/singlePhoto/Gallery24.jpg";
import Single25 from "../../assets/images/gallery/singlePhoto/Gallery25.jpg";
import Single26 from "../../assets/images/gallery/singlePhoto/Gallery26.jpg";
import Single27 from "../../assets/images/gallery/singlePhoto/Gallery27.jpg";
import Single28 from "../../assets/images/gallery/singlePhoto/Gallery28.jpg";
import Single29 from "../../assets/images/gallery/singlePhoto/Gallery29.jpg";
import Single3 from "../../assets/images/gallery/singlePhoto/Gallery3.jpg";
import Single30 from "../../assets/images/gallery/singlePhoto/Gallery30.jpg";
import Single31 from "../../assets/images/gallery/singlePhoto/Gallery31.jpg";
import Single32 from "../../assets/images/gallery/singlePhoto/Gallery32.jpg";
import Single33 from "../../assets/images/gallery/singlePhoto/Gallery33.jpg";
import Single34 from "../../assets/images/gallery/singlePhoto/Gallery34.jpg";
import Single35 from "../../assets/images/gallery/singlePhoto/Gallery35.jpg";
import Single36 from "../../assets/images/gallery/singlePhoto/Gallery36.jpg";
import Single37 from "../../assets/images/gallery/singlePhoto/Gallery37.jpg";
import Single38 from "../../assets/images/gallery/singlePhoto/Gallery38.jpg";
import Single39 from "../../assets/images/gallery/singlePhoto/Gallery39.jpg";
import Single4 from "../../assets/images/gallery/singlePhoto/Gallery4.jpg";
import Single40 from "../../assets/images/gallery/singlePhoto/Gallery40.jpg";
import Single41 from "../../assets/images/gallery/singlePhoto/Gallery41.jpg";
import Single42 from "../../assets/images/gallery/singlePhoto/Gallery42.jpg";
import Single43 from "../../assets/images/gallery/singlePhoto/Gallery43.jpg";
import Single44 from "../../assets/images/gallery/singlePhoto/Gallery44.jpg";
import Single45 from "../../assets/images/gallery/singlePhoto/Gallery45.jpg";
import Single46 from "../../assets/images/gallery/singlePhoto/Gallery46.jpg";
import Single47 from "../../assets/images/gallery/singlePhoto/Gallery47.jpg";
import Single48 from "../../assets/images/gallery/singlePhoto/Gallery48.jpg";
import Single49 from "../../assets/images/gallery/singlePhoto/Gallery49.jpg";
import Single5 from "../../assets/images/gallery/singlePhoto/Gallery5.jpg";
import Single50 from "../../assets/images/gallery/singlePhoto/Gallery50.jpg";
import Single51 from "../../assets/images/gallery/singlePhoto/Gallery51.jpg";
import Single52 from "../../assets/images/gallery/singlePhoto/Gallery52.jpg";
import Single53 from "../../assets/images/gallery/singlePhoto/Gallery53.jpg";
import Single54 from "../../assets/images/gallery/singlePhoto/Gallery54.jpg";
import Single55 from "../../assets/images/gallery/singlePhoto/Gallery55.jpg";
import Single56 from "../../assets/images/gallery/singlePhoto/Gallery56.jpg";
import Single57 from "../../assets/images/gallery/singlePhoto/Gallery57.jpg";
import Single58 from "../../assets/images/gallery/singlePhoto/Gallery58.jpg";
import Single59 from "../../assets/images/gallery/singlePhoto/Gallery59.jpg";
import Single6 from "../../assets/images/gallery/singlePhoto/Gallery6.jpg";
import Single60 from "../../assets/images/gallery/singlePhoto/Gallery60.jpg";
import Single61 from "../../assets/images/gallery/singlePhoto/Gallery61.jpg";
import Single62 from "../../assets/images/gallery/singlePhoto/Gallery62.jpg";
import Single63 from "../../assets/images/gallery/singlePhoto/Gallery63.jpg";
import Single64 from "../../assets/images/gallery/singlePhoto/Gallery64.jpg";
import Single65 from "../../assets/images/gallery/singlePhoto/Gallery65.jpg";
import Single66 from "../../assets/images/gallery/singlePhoto/Gallery66.jpg";
import Single67 from "../../assets/images/gallery/singlePhoto/Gallery67.jpg";
import Single68 from "../../assets/images/gallery/singlePhoto/Gallery68.jpg";
import Single69 from "../../assets/images/gallery/singlePhoto/Gallery69.jpg";
import Single7 from "../../assets/images/gallery/singlePhoto/Gallery7.jpg";
import Single70 from "../../assets/images/gallery/singlePhoto/Gallery70.jpg";
import Single71 from "../../assets/images/gallery/singlePhoto/Gallery71.jpg";
import Single72 from "../../assets/images/gallery/singlePhoto/Gallery72.jpg";
import Single73 from "../../assets/images/gallery/singlePhoto/Gallery73.jpg";
import Single74 from "../../assets/images/gallery/singlePhoto/Gallery74.jpg";
import Single75 from "../../assets/images/gallery/singlePhoto/Gallery75.jpg";
import Single76 from "../../assets/images/gallery/singlePhoto/Gallery76.jpg";
import Single77 from "../../assets/images/gallery/singlePhoto/Gallery77.jpg";
import Single78 from "../../assets/images/gallery/singlePhoto/Gallery78.jpg";
import Single79 from "../../assets/images/gallery/singlePhoto/Gallery79.jpg";
import Single8 from "../../assets/images/gallery/singlePhoto/Gallery8.jpg";
import Single80 from "../../assets/images/gallery/singlePhoto/Gallery80.jpg";
import Single81 from "../../assets/images/gallery/singlePhoto/Gallery81.jpg";
import Single82 from "../../assets/images/gallery/singlePhoto/Gallery82.jpg";
import Single83 from "../../assets/images/gallery/singlePhoto/Gallery83.jpg";
import Single84 from "../../assets/images/gallery/singlePhoto/Gallery84.jpg";
import Single85 from "../../assets/images/gallery/singlePhoto/Gallery85.jpg";
import Single9 from "../../assets/images/gallery/singlePhoto/Gallery9.jpg";

const AllPhoto = () => {
  const sliders = [
    Single1,
    Single2,
    Single3,
    Single4,
    Single5,
    Single6,
    Single7,
    Single8,
    Single9,
    Single10,
    Single11,
    Single12,
    Single13,
    Single14,
    Single15,
    Single16,
    Single17,
    Single18,
    Single19,
    Single20,
    Single21,
    Single22,
    Single23,
    Single24,
    Single25,
    Single26,
    Single27,
    Single28,
    Single29,
    Single30,
    Single31,
    Single32,
    Single33,
    Single34,
    Single35,
    Single36,
    Single37,
    Single38,
    Single39,
    Single40,
    Single41,
    Single42,
    Single43,
    Single44,
    Single45,
    Single46,
    Single47,
    Single48,
    Single49,
    Single50,
    Single51,
    Single52,
    Single53,
    Single54,
    Single55,
    Single56,
    Single57,
    Single58,
    Single59,
    Single60,
    Single61,
    Single62,
    Single63,
    Single64,
    Single65,
    Single66,
    Single67,
    Single68,
    Single69,
    Single70,
    Single71,
    Single72,
    Single73,
    Single74,
    Single75,
    Single76,
    Single77,
    Single78,
    Single79,
    Single80,
    Single81,
    Single82,
    Single83,
    Single84,
    Single85,
  ];
  const sliders2 = [
    Gallery1,
    Gallery2,
    Gallery3,
    Gallery4,
    Gallery5,
    Gallery6,
    Gallery7,
    Gallery8,
    Gallery9,
    Gallery10,
    Gallery11,
    Gallery12,
    Gallery13,
    Gallery14,
    Gallery15,
    Gallery16,
    Gallery17,
    Gallery18,
    Gallery19,
    Gallery20,
    Gallery21,
    Gallery22,
    Gallery23,
    Gallery24,
    Gallery25,
    Gallery26,
    Gallery27,
    Gallery28,
    Gallery29,
    Gallery30,
    Gallery31,
    Gallery32,
    Gallery33,
    Gallery34,
    Gallery35,
    Gallery36,
    Gallery37,
    Gallery38,
    Gallery39,
    Gallery40,
    Gallery41,
    Gallery42,
    Gallery43,
    Gallery44,
    Gallery45,
    Gallery46,
    Gallery47,
    Gallery48,
    Gallery49,
    Gallery50,
    Gallery51,
    Gallery52,
  ];

  return (
    <div className="mx-auto grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-3 lg:grid-cols-4 my-4 md:my-8">
      {sliders.map((data, inx) => (
        <div key={inx}>
          <Gellary data={data}></Gellary>
        </div>
      ))}
      {sliders2.map((data, inx) => (
        <div key={inx}>
          <Gellary2 data={data}></Gellary2>
        </div>
      ))}
    </div>
  );
};

export default AllPhoto;
