import React, { useState } from "react";

import rec1 from '../../assets/images/recomandation/R1.JPG'
import rec2 from '../../assets/images/recomandation/R2.JPG'
import rec3 from '../../assets/images/recomandation/R3.JPG'
import rec4 from '../../assets/images/recomandation/R4.JPG'
import rec5 from '../../assets/images/recomandation/R5.JPG'
import rec6 from '../../assets/images/recomandation/R6.JPG'
import rec7 from '../../assets/images/recomandation/R7.JPG'
import rec8 from '../../assets/images/recomandation/R8.JPG'

const HomeCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      src: rec2,
      alt: "Slide 1",
    },
    {
      src: rec1,
      alt: "Slide 2",
    },
    {
      src: rec3,
      alt: "Slide 3",
    },
    {
      src: rec4,
      alt: "Slide 3",
    },
    {
      src: rec5,
      alt: "Slide 3",
    },
    {
      src: rec6,
      alt: "Slide 3",
    },
    {
      src: rec7,
      alt: "Slide 3",
    },
    {
      src: rec8,
      alt: "Slide 3",
    },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="relative rounded-lg overflow-hidden shadow-lg">
        {/* Carousel wrapper */}
        <div className="relative ">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`${
                index === currentIndex ? "block" : "hidden"
              } duration-700 ease-in-out`}
            >
              <img
                src={slide.src}
                className="object-cover w-full h-full"
                alt={slide.alt}
              />
              {slide.caption && (
                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-semibold text-white md:text-2xl dark:text-gray-800">
                  {slide.caption}
                </span>
              )}
            </div>
          ))}
        </div>
        {/* Slider indicators */}
        <div className="flex absolute bottom-5 left-1/2 z-30 -translate-x-1/2 space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex
                  ? "bg-gray-400"
                  : "bg-gray-300 hover:bg-gray-400"
              } focus:outline-none transition`}
            ></button>
          ))}
        </div>
        {/* Slider controls */}
        <button
          onClick={handlePrev}
          className="flex absolute top-1/2 left-3 z-40 items-center justify-center w-10 h-10 bg-gray-200/50 rounded-full bg-[#284BE4] focus:outline-none transition text-white"
        >
          <svg
            className="w-5 h-5 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          className="flex absolute top-1/2 right-3 z-40 items-center justify-center w-10 h-10 bg-gray-200/50 rounded-full bg-[#284BE4] focus:outline-none transition text-white"
        >
          <svg
            className="w-5 h-5 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default HomeCarousel;
