import PartImg1 from "../assets/images/work/1.png";
import PartImg10 from "../assets/images/work/10.png";
import PartImg2 from "../assets/images/work/2.png";
import PartImg3 from "../assets/images/work/3.png";
import PartImg4 from "../assets/images/work/4.png";
import PartImg5 from "../assets/images/work/5.png";
import PartImg6 from "../assets/images/work/6.png";
import PartImg7 from "../assets/images/work/7.png";
import PartImg8 from "../assets/images/work/8.png";
import PartImg9 from "../assets/images/work/9.png";

export const partners = [
  {
    partImg: PartImg1,
    partHoverImg: PartImg1,
    partLink: "#",
  },
  {
    partImg: PartImg2,
    partHoverImg: PartImg2,
    partLink: "#",
  },
  {
    partImg: PartImg3,
    partHoverImg: PartImg3,
    partLink: "#",
  },
  {
    partImg: PartImg4,
    partHoverImg: PartImg4,
    partLink: "#",
  },
  {
    partImg: PartImg5,
    partHoverImg: PartImg5,
    partLink: "#",
  },
  {
    partImg: PartImg6,
    partHoverImg: PartImg6,
    partLink: "#",
  },
  {
    partImg: PartImg7,
    partHoverImg: PartImg7,
    partLink: "#",
  },
  {
    partImg: PartImg8,
    partHoverImg: PartImg8,
    partLink: "#",
  },
  {
    partImg: PartImg9,
    partHoverImg: PartImg9,
    partLink: "#",
  },
  {
    partImg: PartImg10,
    partHoverImg: PartImg10,
    partLink: "#",
  },
];
