import "./YoutubeVideo.css";

import React, { useEffect, useState } from "react";

import video from '../../assets/video/bgvideo2.mp4'

const YoutubePage = () => {
  const words = [" Zayed Khan     ", "Actor     ", "Film Producer"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [letterIndex, setLetterIndex] = useState(0);

  useEffect(() => {
    const handleType = () => {
      const currentWord = words[currentWordIndex];
      if (isDeleting) {
        setText(currentWord.substring(0, letterIndex - 1));
        setLetterIndex(letterIndex - 1);

        if (letterIndex === 0) {
          setIsDeleting(false);
          setCurrentWordIndex((prevIndex) =>
            prevIndex === words.length - 1 ? 0 : prevIndex + 1
          );
        }
      } else {
        setText(currentWord.substring(0, letterIndex + 1));
        setLetterIndex(letterIndex + 1);

        if (letterIndex === currentWord.length) {
          setIsDeleting(true);
        }
      }
    };

    const typingSpeed = isDeleting ? 100 : 150;
    const timeout = setTimeout(handleType, typingSpeed);

    return () => clearTimeout(timeout);
  }, [letterIndex, isDeleting, currentWordIndex]);
  return (
    <div className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full z-[1]">
      <div className="container sm:container md:container lg:container xl:container 2xl:max-w-[110.3125rem] mx-auto">
        <section className="relative h-screen flex flex-col items-center justify-center text-center text-white z-50">
          <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
            <video
              className="min-w-full min-h-full absolute object-cover"
              src={video}
              autoPlay
              muted
              loop
            />
          </div>
          <div className="video-content space-y-2 z-10 bg-opacity-60">
            <h3 className="font-light text-3xl">Hello I'm</h3>
            <div className="lg:text-[80px] md:text-[45px] sm:text-[45px] text-[34px] font-semibold sm:mb-[30px] mb-[10px] leading-[120%] designers2">
              <h1>
                <span id="typewriter">{text}</span>|
              </h1>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default YoutubePage;