import { ReactComponent as CoffeeCupIcon } from "../lib/icons/C.svg";
import { ReactComponent as FinishedProjectsIcon } from "../lib/icons/B.svg";
import { ReactComponent as HappyCustomersIcon } from "../lib/icons/A.svg";
import { ReactComponent as WorkingHoursIcon } from "../lib/icons/WorkingHours.svg";

export const funFacts = [
  {
    factIcon: (
      <HappyCustomersIcon className="w-[3.125rem] fill-accent"></HappyCustomersIcon>
    ),
    factCount: "15",
    factCap: "Number of Awards",
  },
  {
    factIcon: (
      <FinishedProjectsIcon className="w-[3.125rem] fill-accent"></FinishedProjectsIcon>
    ),
    factCount: "26",
    factCap: "Number Of Movies",
  },
  {
    factIcon: (
      <CoffeeCupIcon className="w-[3.125rem] fill-accent"></CoffeeCupIcon>
    ),
    factCount: "10",
    factCap: "Commercial Ads",
  },
  {
    factIcon: (
      <WorkingHoursIcon className="w-[3.125rem] fill-accent"></WorkingHoursIcon>
    ),
    factCount: "50",
    factCap: "World Wide projects",
  },
];
