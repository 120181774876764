import B1 from "../assets/images/blog/B1.png";
import B2 from "../assets/images/blog/B2.png";
import PostImg3 from "../assets/images/resources/postImg2-3.jpg";
import PostImg4 from "../assets/images/resources/postImg2-4.jpg";
import PostImg5 from "../assets/images/resources/postImg2-5.jpg";
import PostImg6 from "../assets/images/resources/postImg2-6.jpg";

export const posts2 = [
  {
    id: 1,
    postImg: B1,
    postDate: "26",
    postMonth: "August",
    postDateLink: "#",
    postTitle: "Zayed Khan as a freedom fighter",
    postLink: "https://www.jugantor.com/entertainment/843094",
    postAuthor: "Jugantor",
    postAuthorLink: "#",
  },
  {
    id: 2,
    postImg: B2,
    postDate: "19",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Ilias Kanchan elected President, Zayed Khan General Secretary",
    postLink:
      "https://www.thedailystar.net/entertainment/tv-film/news/ilias-kanchan-elected-president-zayed-khan-general-secretary-2949676",
    postAuthor: "The Daily Star",
    postAuthorLink: "#",
  },
  {
    id: 3,
    postImg: PostImg3,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Project that began allow second phase in worth",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 4,
    postImg: PostImg4,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Multiethnic colleagues sitting at desk looking",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
  {
    id: 5,
    postImg: PostImg5,
    postDate: "21",
    postMonth: "January",
    postDateLink: "#",
    postTitle: "Shot of two work colleage using a digital tablet",
    postLink: "#",
    postAuthor: "Berline Tokyo",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Technology,",
        cateLink: "#",
      },
      {
        cateItem: "Events",
        cateLink: "#",
      },
    ],
  },
  {
    id: 6,
    postImg: PostImg6,
    postDate: "15",
    postMonth: "June",
    postDateLink: "#",
    postTitle: "Beautiful Specialist with Short Pink Hair Talks",
    postLink: "#",
    postAuthor: "John Doe",
    postAuthorLink: "#",
    cateList: [
      {
        cateItem: "Design,",
        cateLink: "#",
      },
      {
        cateItem: "Events,",
        cateLink: "#",
      },
      {
        cateItem: "Technology",
        cateLink: "#",
      },
    ],
  },
];
