import FreelanceWorkMockup from "../../assets/images/bgimage-1.png";
import { Link } from "react-router-dom";
import PatternImg2 from "../../assets/images/patternImg2.jpg";

const FreelanceWork2 = () => {
  return (
    <section className="freelanceWorkWrap w-full relative z-[1]">
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <div className="freelanceWork p-[1.875rem] sm:p-[2.5rem] mt-[2.875rem] md:mt-0 md:pt-[4.0625rem] md:pb-[3.4375rem] md:pr-[3.75rem] xl:pr-[10.625rem] relative w-full">
          <div
            className="before:absolute before:inset-0 before:bg-accent before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-r-[3.125rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-r-[3.125rem] inset-0 md:left-auto w-full md:w-screen bg-no-repeat bg-center bg-cover z-[-1] bg-accent"
            style={{ backgroundImage: `url(${PatternImg2})` }}
          ></div>
          <div className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1 text-center md:text-left items-center">
            <div className="md:order-1">
              <div className="freelanceImg w-full">
                <img
                  src={FreelanceWorkMockup}
                  alt="Freelance Work Mockup"
                ></img>
                {/* Freelance Image */}
              </div>
            </div>
            <div>
              <div className="freelanceInfo w-full">
                <h5 className="text-white font-Poppins text-[1rem] sm:text-[1.125rem] md:text-[1.125rem] lg:text-[1.25rem] text-justify">
                  Zayed Khan is a celebrated film actor in Bangladesh, known for
                  his compelling performances and versatility on screen. With a
                  career that spans over two decades, he has garnered a
                  dedicated fan base and earned recognition for his
                  contributions to the Bangladeshi film industry. In addition to
                  his acting, Zayed served as the General Secretary of the
                  Bangladesh Film Artiste Association, where he championed the
                  rights and welfare of artists, making significant strides for
                  his peers in the industry. His leadership and commitment to
                  improving the conditions for fellow actors reflect his passion
                  for the art of filmmaking. Zayed Khan remains a prominent
                  figure in Bangladeshi cinema, inspiring aspiring artists and
                  continuing to entertain audiences with his captivating roles.
                </h5>
                {/* Freelance Info */}
              </div>
            </div>
          </div>
          {/* Freelance Work */}
        </div>
      </div>
      {/* Freelance Work Wrap */}
    </section>
  );
};

export default FreelanceWork2;
