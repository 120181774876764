import Chanchal from "../assets/images/Chanchal.png";
import Dipjol from "../assets/images/Dipjol.png";
import Ferdous from "../assets/images/Ferdous.png";
import Mosharraf from "../assets/images/Mosharraf.png";
import Nusraat from "../assets/images/Nusraat.png";
import Pori from "../assets/images/Pori.png";
import Pritom from "../assets/images/Pritom.png";
import Protic from "../assets/images/Protic.png";
import Riaz from "../assets/images/Riaz.png";
import Shamsuzzaman from "../assets/images/Shamsuzzaman.png";
import StarRate from "../assets/images/5starRate.png";
import TestiImg1 from "../assets/images/resources/testiImg1-1.jpg";
import TestiImg2 from "../assets/images/resources/testiImg1-2.jpg";
import TestiImg3 from "../assets/images/resources/testiImg1-3.jpg";

export const testimonials = [
  {
    testiImg: Pritom,
    testiTitle: "Pritom Hasan",
    testiDesi: "Musician",
    testiDesc:
      "I’ve never seen such a great actor like him. He’s like a chameleon in acting",
    testiRate: StarRate,
  },
  {
    testiImg: Protic,
    testiTitle: "Protic Hasan",
    testiDesi: "Musician",
    testiDesc: "I still can’t get over with your acting in the last movie.",
    testiRate: StarRate,
  },
  {
    testiImg: Riaz,
    testiTitle: "Riaz",
    testiDesi: "Actor",
    testiDesc:
      "Your performance was outstanding, it’s like that role is just for you.",
    testiRate: StarRate,
  },
  {
    testiImg: Chanchal,
    testiTitle: "Chanchal Chowdhury",
    testiDesi: "Actor",
    testiDesc:
      "You lived that character and we can’t imagine any other actor than you in that place.",
    testiRate: StarRate,
  },
  {
    testiImg: Mosharraf,
    testiTitle: "Mosharraf Karim",
    testiDesi: "Actor",
    testiDesc:
      "No matter how many actors come and go, this is an evergreen actor.",
    testiRate: StarRate,
  },
  {
    testiImg: Shamsuzzaman,
    testiTitle: "A.T.M. Shamsuzzaman",
    testiDesi: "Actor",
    testiDesc:
      "I usually don’t ask for an autograph. But If I get to see him, I’ll ask for an autograph.",
    testiRate: StarRate,
  },
  {
    testiImg: Ferdous,
    testiTitle: "Ferdous Ahmed",
    testiDesi: "Actor",
    testiDesc:
      "I know you might heard it thousands of times. But it’s me, I’m your biggest fan, sir.",
    testiRate: StarRate,
  },
  {
    testiImg: Dipjol,
    testiTitle: "Monwar Hossain Dipjol",
    testiDesi: "Actor",
    testiDesc:
      "Your acting is an inspiration to me. I want to be a great actor like you.",
    testiRate: StarRate,
  },
  {
    testiImg: Pori,
    testiTitle: "Pori Moni",
    testiDesi: "Actress",
    testiDesc:
      "I love your performance in every role you played. You’re the greatest actor of this generation.",
    testiRate: StarRate,
  },
  {
    testiImg: Nusraat,
    testiTitle: "Nusraat Faria",
    testiDesi: "Actress",
    testiDesc:
      "You’re such a versatile actor that you own every role you play.",
    testiRate: StarRate,
  },
];
