import CountUp from "react-countup";
import { Link } from "react-router-dom";
import PatternImg from "../../assets/images/patternImg.jpg";
import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ScrollTrigger from "react-scroll-trigger";
import { funFacts } from "../../data/fun-facts";
import { useState } from "react";

const FunFacts = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className="funFactsWrap pt-10 xl:pt-[7.75rem] relative w-full">
      <div className="container sm:container md:container lg:container xl:max-w-[98.125rem] mx-auto">
        <div className="viewAll text-center w-full pb-6 md:pb-8">
          <p className="text-desc2 font-NunitoSans text-[1rem] md:text-[1.125rem]">
            Want to see my professional videos.{" "}
            <Link
              className="text-accent font-Poppins font-medium hover:text-accent2 underline underline-offset-8"
              to="/video"
              title="Click here to View More"
            >
              Click here to View More
            </Link>
          </p>
          {/* View All */}
        </div>
      </div>
      <section className="funFactsWrap pt-20 xl:pt-[13.75rem] relative w-full">
        <div
          className="fixedBg bg-left-top bg-no-repeat opacity-20"
          style={{ backgroundImage: `url(${PatternImg})` }}
        ></div>
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div className="funFactBoxes relative w-full">
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[10px]">
                {funFacts.map(
                  (item, index) =>
                    index < 4 && (
                      <div
                        className="factBox text-center flex flex-col items-center justify-center p-5 w-full relative z-[1] min-h-[11rem] sm:min-h-[15rem]"
                        key={index}
                      >
                        <div
                          className="expBoxBg before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[3.125rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 bg-no-repeat bg-center bg-cover z-[-1] bg-gray"
                          style={{ backgroundImage: `url(${PatternImg2})` }}
                        ></div>
                        <span>{item.factIcon}</span>
                        {counterOn && (
                          <div className="flex">
                            <CountUp
                              start={0}
                              end={item.factCount}
                              duration={4}
                              className="font-Poppins font-bold text-accent2 text-[1.75rem] md:text-[2rem] lg:text-[2.25rem] leading-none mt-3"
                            ></CountUp>
                            <p className="font-Poppins font-bold text-accent2 text-[1.75rem] md:text-[2rem] lg:text-[2.25rem] leading-none mt-3">
                              +
                            </p>
                          </div>
                        )}
                        <h5 className="text-desc2 font-NunitoSans font-bold text-[1rem] md:text-[1.125rem] leading-none mt-3">
                          {item.factCap}
                        </h5>
                      </div>
                    )
                )}
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </section>
    </div>
  );
};

export default FunFacts;
