import "./styles.css";

import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

export default function HomeYoutube() {
  return (
    <div className="App grid grid-rows-1 sm:grid-rows-1 md:grid-rows-1 lg:grid-rows-1 gap-4">
      <YoutubeEmbed embedId="Nu1eSdTWgEs" />
      <YoutubeEmbed embedId="Pv9JE2suCsE" />
    </div>
  );
}
