import React from 'react';
import support from '../../assets/images/Support.jpg';

const Support = () => {
    return (
      <section className="testiWrap py-[4rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
        <div className="flex justify-center">
          <div className="lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
            <div className="lg:w-1/2">
              <div
                className="lg:scale-110 h-80 bg-cover lg:h-full rounded-b-none lg:rounded-lg"
                style={{
                  backgroundImage: `url(${support})`,
                }}
              ></div>
            </div>
            <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none lg:rounded-lg">
              <h2 className="text-3xl text-gray-800 font-bold">
                SUPPORT{" "}
                <span className="text-indigo-600">
                  (Human Welfare Organization)
                </span>
              </h2>
              <p className="mt-4 text-gray-600">
                Aided underprivileged and poverty driven people with foods,cloth
                and blankets across 5 districts in the rural areas of Bangldesh
                (especially during COVID-19 pandemic and during flooding in the
                rural areas).
              </p>
              <div className="mt-4">
                <p
                  href="#"
                  className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded"
                >
                  Zayed Khan (Founder)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Support;