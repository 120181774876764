import React, { Fragment } from "react";

import { AboutUs2 } from "../../components/AboutUs";
import { AwardCarousel } from "../../components/Award/AwardCarousel";
import { BlogPost2 } from "../../components/BlogPosts";
import { BottomBar } from "../../components/BottomBar";
import { ContactUs2 } from "../../components/ContactUs";
import { Education2 } from "../../components/Education";
import { FreelanceWork2 } from "../../components/FreelanceWork";
import { FunFacts } from "../../components/FunFacts";
import { Header2 } from "../../components/Header";
import { Helmet } from "react-helmet";
import ModalConfirm from "../../components/AboutUs/ModalConfirm";
import { MyExperienceAndSkills } from "../../components/MyExperienceAndSkills";
import MyScrollCarousel from "../../components/MyScrollCarousel/MyScrollCarousel";
import P1 from '../../assets/images/ScrollingText/P1.png';
import P2 from '../../assets/images/ScrollingText/P2.png';
import P3 from '../../assets/images/ScrollingText/P3.png';
import { Partners2 } from "../../components/Partners";
import { Portfolio2 } from "../../components/Portfolio";
import { PriceTable } from "../../components/PriceTable";
import S1 from '../../assets/images/ScrollingText/1.png';
import S10 from '../../assets/images/ScrollingText/10.png';
import S11 from '../../assets/images/ScrollingText/11.png';
import S12 from '../../assets/images/ScrollingText/12.png';
import S13 from '../../assets/images/ScrollingText/13.png';
import S14 from '../../assets/images/ScrollingText/14.png';
import S15 from '../../assets/images/ScrollingText/15.png';
import S16 from '../../assets/images/ScrollingText/16.png';
import S17 from '../../assets/images/ScrollingText/17.png';
import S18 from '../../assets/images/ScrollingText/18.png';
import S19 from '../../assets/images/ScrollingText/19.png';
import S2 from '../../assets/images/ScrollingText/2.png';
import S20 from '../../assets/images/ScrollingText/20.png';
import S21 from '../../assets/images/ScrollingText/21.png';
import S22 from '../../assets/images/ScrollingText/22.png';
import S23 from '../../assets/images/ScrollingText/23.png';
import S24 from '../../assets/images/ScrollingText/24.png';
import S25 from '../../assets/images/ScrollingText/25.png';
import S26 from '../../assets/images/ScrollingText/26.png';
import S3 from '../../assets/images/ScrollingText/3.png';
import S4 from '../../assets/images/ScrollingText/4.png';
import S5 from '../../assets/images/ScrollingText/5.png';
import S6 from '../../assets/images/ScrollingText/6.png';
import S7 from '../../assets/images/ScrollingText/7.png';
import S8 from '../../assets/images/ScrollingText/8.png';
import S9 from '../../assets/images/ScrollingText/9.png';
import ST1 from '../../assets/images/ScrollingText/logo.png';
import Support from "./Support";
import { Testimonials2 } from "../../components/Testimonials";
import { VideoBox } from "../../components/VideoBoxes";
import YoutubePage from "../../components/AboutUs/YoutubePage";

const HomePage2 = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Home - Zayed Khan</title>
      </Helmet>

      <Header2></Header2>
      <YoutubePage />
      <div class="relative marquee-wrapper bg-[#284BE4] text-slider h-[90px] overflow-hidden">
        <div class="slide-track">
          <div class="slide flex">
            <img src={S1} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S2} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S3} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S4} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S5} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S6} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S7} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S8} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S9} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S10} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S11} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S12} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S13} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S14} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S15} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S16} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S17} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S18} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S19} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S20} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S21} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S22} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S23} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S24} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S25} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
          <div class="slide flex">
            <img src={S26} alt="" />
            <img className="h-20 w-20 my-auto" src={ST1} alt="" />
          </div>
        </div>
      </div>
      <AboutUs2></AboutUs2>
      <ModalConfirm />
      <div className="my-4">
        <div class="relative marquee-wrapper text-slider bg-[#284BE4] h-[90px] overflow-hidden">
          <div class="slide-track2">
            <div class="slide">
              <img src={P1} alt="" />
            </div>
            <div class="slide">
              <img src={P2} alt="" />
            </div>
            <div class="slide">
              <img src={P3} alt="" />
            </div>
            <div class="slide">
              <img src={P1} alt="" />
            </div>
            <div class="slide">
              <img src={P2} alt="" />
            </div>
            <div class="slide">
              <img src={P3} alt="" />
            </div>
            <div class="slide">
              <img src={P1} alt="" />
            </div>
            <div class="slide">
              <img src={P2} alt="" />
            </div>
            <div class="slide">
              <img src={P3} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <Services2></Services2> */}
      <AwardCarousel />
      <VideoBox></VideoBox>
      <Education2></Education2>
      <MyExperienceAndSkills></MyExperienceAndSkills>
      <FunFacts></FunFacts>
      <Portfolio2></Portfolio2>
      <MyScrollCarousel />
      <FreelanceWork2></FreelanceWork2>
      <PriceTable></PriceTable>
      <Partners2></Partners2>
      <Testimonials2></Testimonials2>
      <Support />
      <BlogPost2></BlogPost2>
      <ContactUs2></ContactUs2>
      <BottomBar></BottomBar>
    </Fragment>
  );
};

export default HomePage2;
