import React, { useState } from "react";

import AllPhoto from "./AllPhoto";
import { Carousel } from "./Carousel";
import { ContactUs2 } from "../ContactUs";
import { Header2 } from "../Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../PageTitle";
import { BottomBar } from "../BottomBar";
import { BlogPost } from "../BlogPosts";

const BlogPage = () => {
  const [activeTab, setActiveTab] = useState("all");

  const showTab = (tabId) => {
    setActiveTab(tabId); // Update the active tab
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Blog - Zayed Khan</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Blog"
        hometitle="Home"
        homeURL="/"
        currentPage="Blogs"
      ></PageTitle>
      <section className="pageWrap py-[3.5rem] md:py-[3.5rem] lg:py-[6.875rem] relative w-full">
        <BlogPost />
        {/* Page Wrap */}
      </section>
      <ContactUs2></ContactUs2>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default BlogPage;
