import { ContactUs} from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/PageTitle";
import React from "react";
import { BottomBar } from "../../components/BottomBar";

const ContactPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact - Zayed Khan</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Contact Me"
        hometitle="Home"
        homeURL="/"
        currentPage="Contact Me"
      ></PageTitle>
      <section className="pageWrap relative w-full">
        <iframe
          className="w-full relative border-0 -mt-[8.125rem] min-h-[35rem] sm:min-h-[45rem] md:min-h-[60.3125rem]"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.0835813498747!2d89.97201478536674!3d22.575977079977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a00013dec2e462b%3A0x290e750a1ad4dd52!2sBalaka%20Club%20Road%2C%20Masimpur%2C%20Pirojpur!5e0!3m2!1sen!2sbd!4v1727382174295!5m2!1sen!2sbd"
          allowfullscreen
          loading="lazy"
          title="Contact Map"
        ></iframe>
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default ContactPage;
