import HomeYoutube2 from "../Youtube/HomeYoutube2";
import PatternImg2 from "../../assets/images/patternImg2.jpg";
import SectionBg from "../../assets/images/secBg9.png";
import { SectionTitleLightFull } from "../SectionTitles";
import { skills } from "../../data/skills";

const Skills2 = () => {
  return (
    <section className="skillsWrap px-0 py-[4.5rem] sm:px-8 sm:py-[5.5rem] md:py-[6.5rem] lg:p-[7.5rem] lg:-mb-[6.25rem] flex justify-center items-center relative w-full">
      <div
        className="bg-center bg-no-repeat bg-cover before:absolute before:inset-0 before:opacity-80 before:bg-accent bg-accent bg-blend-screen lg:rounded-l-[3.125rem] md:rounded-l-8 sm:rounded-l-5 rounded-l-0 overflow-hidden absolute w-[90.3125rem] right-0 top-0 bottom-0"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:max-w-[44.6875rem] mx-auto">
        <HomeYoutube2 />
      </div>
    </section>
  );
};

export default Skills2;
