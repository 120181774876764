import { Gellary } from "./Gellary";
import H1 from '../../assets/images/gallery/H1.jpg';
import H2 from '../../assets/images/gallery/H2.jpg';
import H3 from '../../assets/images/gallery/H3.jpg';
import H4 from '../../assets/images/gallery/H4.jpg';
import H5 from '../../assets/images/gallery/H5.jpg';
import H6 from '../../assets/images/gallery/H6.jpg';
import H7 from '../../assets/images/gallery/H7.jpg';
import H8 from '../../assets/images/gallery/H8.jpg';
import H9 from '../../assets/images/gallery/H9.jpg';

const ImageGallery = () => {
  const sliders = [H4, H5, H6, H7,H9, H8, H1, H2, H3];
  return (
    <div className="mx-auto grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-2 lg:grid-cols-3">
      {sliders.map((data, inx) => (
        <div key={inx}>
          <Gellary data={data}></Gellary>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
