import React, { useState } from "react";

import { ContactUs2 } from "../ContactUs";
import { Header2 } from "../Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../PageTitle";
import YoutubeEmbed from "../Youtube/YoutubeEmbed";
import { BottomBar } from "../BottomBar";

const VideoSection = () => {
  const [activeTab, setActiveTab] = useState("all");

  const showTab = (tabId) => {
    setActiveTab(tabId); // Update the active tab
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Video - Zayed Khan</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Videos"
        hometitle="Home"
        homeURL="/"
        currentPage="Videos"
      ></PageTitle>
      <section className="pageWrap py-[3.5rem] md:py-[3.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="pageInner grid gap-10 md:gap-20 grid-cols-1 w-full">
            <div className="servicesBoxes relative w-full">
              {/* Tab Buttons */}
              <div className="bg-blue-500 p-2 rounded-t-lg mb-4">
                <div className="flex justify-center space-x-4">
                  <button
                    className={`py-2.5 px-6 rounded-lg text-sm font-medium bg-[#1A111F] text-white tab-button ${
                      activeTab === "all" ? "active" : ""
                    }`}
                    onClick={() => showTab("all")}
                  >
                    All
                  </button>
                  <button
                    className={`py-2.5 px-6 rounded-lg text-sm font-medium bg-[#1A111F] text-white tab-button ${
                      activeTab === "interview" ? "active" : ""
                    }`}
                    onClick={() => showTab("interview")}
                  >
                    Interview
                  </button>
                  <button
                    className={`py-2.5 px-6 rounded-lg text-sm font-medium bg-[#1A111F] text-white tab-button ${
                      activeTab === "other" ? "active" : ""
                    }`}
                    onClick={() => showTab("other")}
                  >
                    Other
                  </button>
                </div>
              </div>

              {/* Tab Content */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[1.875rem]">
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="MeoWwnmGTrA" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="DOX4zcj19vQ" />
                )}
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="Pv9JE2suCsE" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="wIFCKosI2k8" />
                )}
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="zu3PHQfbJpo" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="vCKdB4fiuRM" />
                )}
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="Nu1eSdTWgEs" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="Tfyxvfj5P7I" />
                )}
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="VHsJjx79oa0" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="qrL8HO6ZDP0" />
                )}
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="i7Ohma1_Pas" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="QKwxWW1Ktqg" />
                )}
                {(activeTab === "all" || activeTab === "other") && (
                  <YoutubeEmbed embedId="242LtkAuZ4A" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="dL_QMBPqy3A" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="8Z9cg2LBQ5U" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="nYu1GoB5L6Q" />
                )}
                {(activeTab === "all" || activeTab === "interview") && (
                  <YoutubeEmbed embedId="HIBaFa_j2WE" />
                )}
              </div>

              <style>{`
        .tab-button.active {
          border-color: #4299e1;
          color: #FFF;
          background: linear-gradient(90deg, rgba(40,75,228,1) 28%, rgba(56,46,226,1) 69%);
        }
      `}</style>
              {/* Services Boxes */}
            </div>
            {/* Page Inner */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <ContactUs2></ContactUs2>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default VideoSection;
