import AW1 from '../../assets/images/awards/AW1.JPG'
import AW10 from '../../assets/images/awards/AW10.JPG'
import AW11 from '../../assets/images/awards/AW11.JPG'
import AW12 from '../../assets/images/awards/AW12.JPG'
import AW13 from '../../assets/images/awards/AW13.JPG'
import AW14 from '../../assets/images/awards/AW14.JPG'
import AW15 from '../../assets/images/awards/AW15.JPG'
import AW16 from '../../assets/images/awards/AW16.JPG'
import AW17 from '../../assets/images/awards/AW17.JPG'
import AW18 from '../../assets/images/awards/AW18.JPG'
import AW19 from '../../assets/images/awards/AW19.JPG'
import AW2 from '../../assets/images/awards/AW2.JPG'
import AW20 from '../../assets/images/awards/AW20.JPG'
import AW21 from '../../assets/images/awards/AW21.JPG'
import AW22 from '../../assets/images/awards/AW22.JPG'
import AW23 from '../../assets/images/awards/AW23.JPG'
import AW24 from '../../assets/images/awards/AW24.JPG'
import AW25 from '../../assets/images/awards/AW25.JPG'
import AW3 from '../../assets/images/awards/AW3.JPG'
import AW4 from '../../assets/images/awards/AW4.JPG'
import AW5 from '../../assets/images/awards/AW5.JPG'
import AW6 from '../../assets/images/awards/AW6.JPG'
import AW7 from '../../assets/images/awards/AW7.JPG'
import AW8 from '../../assets/images/awards/AW8.JPG'
import AW9 from '../../assets/images/awards/AW9.JPG'
import { SectionTitle3 } from '../SectionTitles'
import { useState } from "react";

export const AwardCarousel = () => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const sliderImages = [
    {
      img: AW1,
    },
    {
      img: AW2,
    },
    {
      img: AW3,
    },
    {
      img: AW4,
    },
    {
      img: AW5,
    },
    {
      img: AW6,
    },
    {
      img: AW7,
    },
    {
      img: AW8,
    },
    {
      img: AW9,
    },
    {
      img: AW10,
    },
    {
      img: AW11,
    },
    {
      img: AW12,
    },
    {
      img: AW13,
    },
    {
      img: AW14,
    },
    {
      img: AW15,
    },
    {
      img: AW16,
    },
    {
      img: AW17,
    },
    {
      img: AW18,
    },
    {
      img: AW19,
    },
    {
      img: AW20,
    },
    {
      img: AW21,
    },
    {
      img: AW22,
    },
    {
      img: AW23,
    },
    {
      img: AW24,
    },
    {
      img: AW25,
    },
  ];

  const prevSlider = () =>
    setCurrentSlider((currentSlider) =>
      currentSlider === 0 ? sliderImages.length - 1 : currentSlider - 1
    );
  const nextSlider = () =>
    setCurrentSlider((currentSlider) =>
      currentSlider === sliderImages.length - 1 ? 0 : currentSlider + 1
    );
  return (
    <div className="servicesWrap md:pb-[5.5rem] lg:pb-[6.25rem] relative w-full">
      <div className="container sm:container md:container lg:container xl:max-w-[75rem] mx-auto">
        <SectionTitle3
          title="My"
          titleInner="Awards"
          desc=""
        ></SectionTitle3>
        <div className="max-w-6xl mx-auto h-[400px] md:h-[670px] flex flex-col xl:flex-row items-center overflow-hidden gap-5 lg:gap-10 relative">
          <div className="absolute w-full h-full flex items-center justify-between z-50 px-5">
            {/* arrow left */}
            <button
              onClick={prevSlider}
              className="flex justify-center items-center bg-[#284BE4]  rounded-full w-6 h-6 md:w-8 md:h-8"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="w-4 h-4 md:w-6 md:h-6 icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#FFF"
                    d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"
                  ></path>
                </g>
              </svg>
            </button>
            {/* arrow right */}
            <button
              onClick={nextSlider}
              className="flex justify-center items-center bg-[#284BE4]  rounded-full w-6 h-6 md:w-8 md:h-8"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="w-4 h-4 md:w-6 md:h-6 icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                transform="rotate(180)"
              >
                <g strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#FFF"
                    d="M685.248 104.704a64 64 0 010 90.496L368.448 512l316.8 316.8a64 64 0 01-90.496 90.496L232.704 557.248a64 64 0 010-90.496l362.048-362.048a64 64 0 0190.496 0z"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
          {/* slider container */}
          <div
            className="h-[400px] md:h-[670px] w-2/3 ml-auto relative ease-linear duration-300 flex items-center"
            style={{ transform: `translateX(-${currentSlider * 50}%)` }}
          >
            {/* sliders */}
            {sliderImages.map((slide, inx) => (
              <div
                key={inx}
                className={`${
                  currentSlider === inx
                    ? "h-[240px] sm:h-[310px] md:h-[480px] lg:h-[580px]"
                    : "h-[220px] sm:h-[260px] md:h-[380px] lg:h-[480px] scale-95 opacity-40"
                } min-w-[50%] relative duration-200`}
                style={{ perspective: "200px" }}
              >
                <img
                  src={slide.img}
                  className="w-full h-full bg-gray-900 rounded-lg duration-300"
                  alt={slide.tags}
                  style={{
                    transform: `${
                      currentSlider - 1 === inx
                        ? "rotateY(4deg)"
                        : currentSlider + 1 === inx
                        ? "rotateY(-4deg)"
                        : ""
                    }`,
                    transformStyle: "preserve-3d",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
