import React, { useState } from "react";

import AllPhoto from "./AllPhoto";
import { Carousel } from "./Carousel";
import { ContactUs2 } from "../ContactUs";
import { Header2 } from "../Header";
import { Helmet } from "react-helmet";
import { PageTitle } from "../PageTitle";
import { BottomBar } from "../BottomBar";

const Photo = () => {
  const [activeTab, setActiveTab] = useState("all");

  const showTab = (tabId) => {
    setActiveTab(tabId); // Update the active tab
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Photo - Zayed Khan</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Photo"
        hometitle="Home"
        homeURL="/"
        currentPage="Photos"
      ></PageTitle>
      <section className="pageWrap py-[3.5rem] md:py-[3.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <Carousel />
          <div className="pageInner grid gap-10 md:gap-20 grid-cols-1 w-full py-4 md:py-8">
            <div className="servicesBoxes relative w-full">
              <AllPhoto />

              <style>{`
        .tab-button.active {
          border-color: #4299e1;
          color: #FFF;
          background: linear-gradient(90deg, rgba(40,75,228,1) 28%, rgba(56,46,226,1) 69%);
        }
      `}</style>
              {/* Services Boxes */}
            </div>
            {/* Page Inner */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <ContactUs2></ContactUs2>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default Photo;
