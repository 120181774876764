import React, { useEffect } from "react";

import popup from '../../assets/images/popup.png';

const ModalConfirm = () => {
  const openModal = (modalId) => {
    document.getElementById(modalId).style.display = "block";
    document.body.classList.add("overflow-y-hidden");
  };

  const closeModal = (modalId) => {
    document.getElementById(modalId).style.display = "none";
    document.body.classList.remove("overflow-y-hidden");
  };

  useEffect(() => {
    openModal("modelConfirm");

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        document.body.classList.remove("overflow-y-hidden");
        const modals = document.getElementsByClassName("modal");
        Array.prototype.slice.call(modals).forEach((modal) => {
          modal.style.display = "none";
        });
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div
      id="modelConfirm"
      className="fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
    >
      <div className="relative top-40 lg:top-60 mx-auto shadow-xl rounded-md bg-white max-w-lg">
        <div className="flex justify-end p-2 -mb-12 z-10">
          <button
            onClick={() => closeModal("modelConfirm")}
            type="button"
            className="text-accent bg-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center z-10"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <img
          src={popup}
          className="min-w-[350px] md:min-w-[350px] min-h-[250px] md:min-h-[300px] bg-black/20"
          alt="my_photo"
        />
      </div>
    </div>
  );
};

export default ModalConfirm;