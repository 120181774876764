import HomeCarousel from "../HomeCarousel/HomeCarousel";
import { Link } from "react-router-dom";
import PatternBg from "../../assets/images/patternImg2.jpg";
import PatternBg2 from "../../assets/images/patternImg4.jpg";
import SectionBg from "../../assets/images/secBg10.png";
import { SectionTitle3 } from "../SectionTitles";
import { priceTable } from "../../data/price-table";

const PriceTable = () => {
  return (
    <section className="priceTableWrap pt-[4.5rem] sm:pt-[5.5rem] md:pt-[6.25rem] relative w-full">
      <div
        className="top-[-15.625rem] bg-center bg-cover bg-no-repeat before:absolute before:z-[1] before:inset-0 before:opacity-[.85] before:bg-gray bg-gray bg-blend-multiply overflow-hidden inset-0 bottom-[14.375rem] z-[-2] absolute"
        style={{ backgroundImage: `url(${SectionBg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
        <SectionTitle3
          title="Testimonial"
          titleInner=""
          desc=""
        ></SectionTitle3>

        <HomeCarousel />
      </div>
      {/* Price Table Wrap */}
    </section>
  );
};

export default PriceTable;
