import * as FiIcons from "react-icons/fi";

export const ResponsiveMenuData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Video",
    path: "/video",
  },
  {
    title: "Photo",
    path: "/photo",
  },
  {
    title: "Blog",
    path: "/blog",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];
