import { ReactComponent as FacebookIcon } from "../lib/icons/Fb.svg";
import { ReactComponent as ImIcon } from "../lib/icons/Im.svg";
import { ReactComponent as InstagramIcon } from "../lib/icons/Ig.svg";
import { ReactComponent as WikiIcon } from "../lib/icons/W.svg";

export const social = [
  {
    socialIcon: (
      <FacebookIcon className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></FacebookIcon>
    ),
    socialLink: "https://www.facebook.com/zkhanbcd?mibextid=LQQJ4d",
    socialTitle: "Facebook",
  },
  {
    socialIcon: (
      <InstagramIcon className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></InstagramIcon>
    ),
    socialLink: "https://www.instagram.com/zayed._.khan_",
    socialTitle: "Instagram",
  },
  {
    socialIcon: (
      <FacebookIcon className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></FacebookIcon>
    ),
    socialLink: "https://www.facebook.com/zayedkhan17?mibextid=LQQJ4d",
    socialTitle: "Facebook",
  },
  {
    socialIcon: (
      <ImIcon className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></ImIcon>
    ),
    socialLink: "https://www.imdb.com/name/nm5274383/",
    socialTitle: "IMDb",
  },
  {
    socialIcon: (
      <WikiIcon className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></WikiIcon>
    ),
    socialLink: "https://en.wikipedia.org/wiki/Zayed_Khan_(Bangladeshi_actor)",
    socialTitle: "Wikipedia",
  },
];
